<template>
  <div class="filter-area">
    <div class="font-weight-bold text-h5 mb-4">Phiếu trưng bày</div>
    <v-card class="tp-filter-scroll pa-5" flat>
      <div class="">
        <div class="font-weight-bold mb-2">Trạng thái</div>
        <v-checkbox
          v-model="selectedStatus"
          class="mt-1"
          dense
          hide-details
          v-for="item in status"
          :label="item.label"
          :key="item.value"
          :value="item.value"
        ></v-checkbox>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Hành động</div>
        <v-checkbox
          v-model="selectedAction"
          v-for="item in ACTION_STATUS"
          :label="item.label"
          :key="item.value"
          :value="item.value"
          class="mt-1"
          dense
          hide-details
        ></v-checkbox>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Chi nhánh</div>
        <input-autocomplete
          class="tp-filter-autocomplete"
          :items="branches"
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          multiple
          placeholder="Chọn chi nhánh"
          v-model="branchesFilter"
          :updateValue="updateBranchesFilter"
        ></input-autocomplete>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Hãng</div>
        <input-autocomplete
          class="tp-filter-autocomplete"
          :items="brands"
          item-text="name"
          v-model="brandsFilter"
          item-value="id"
          no-data-text="Không có dữ liệu"
          multiple
          placeholder="Chọn hãng"
          :updateValue="updateBrandsFilter"
        ></input-autocomplete>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Loại sản phẩm</div>
        <input-autocomplete
          class="tp-filter-autocomplete"
          :items="productTypes"
          item-text="name"
          item-value="id"
          v-model="productTypesFilter"
          no-data-text="Không có dữ liệu"
          multiple
          placeholder="Chọn loại sản phẩm"
          :updateValue="updateProductTypesFilter"
        ></input-autocomplete>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Người phụ trách</div>
        <input-autocomplete
          class="tp-filter-autocomplete"
          :items="employees"
          item-text="name"
          item-value="id"
          multiple
          v-model="employeesFilter"
          placeholder="Chọn nhân viên"
          :updateValue="updateEmployees"
        ></input-autocomplete>
      </div>
    </v-card>
  </div>
</template>

<script>
import InputAutocomplete from "../../../../components/common/InputAutocomplete";
import { ACTION_STATUS } from "@/modules/NotSoldWarehouse/utils/static-data";

export default {
  components: {
    InputAutocomplete
  },
  props: {
    actionFilter: {
      type: Array
    },
    employeesFilter: {
      type: Array
    },
    branchesFilter: {
      type: Array
    },
    brandsFilter: {
      type: Array
    },
    productTypesFilter: {
      type: Array
    },
    statusFilter: {
      type: Array
    }
  },
  data() {
    return {
      ACTION_STATUS: ACTION_STATUS,
      status: [
        {
          label: "Đang trưng bày",
          value: 1
        },
        {
          label: "Đã hạ trưng bày",
          value: 2
        },
        {
          label: "Đã kiểm tra",
          value: 3
        },
        {
          label: "Xử lý xong",
          value: 4
        }
      ]
    };
  },
  created() {
    if (this.employees.length === 0) {
      this.$store.dispatch("EMPLOYEE/getAllEmployees", "ENGINEERING");
    }
    if (this.brands.length === 0) {
      this.$store.dispatch("BRAND/getAllBrands");
    }
    if (this.productTypes.length === 0) {
      this.$store.dispatch("PRODUCT_TYPE/getAllProductTypes");
    }
    if (this.branches.length === 0) {
      this.$store.dispatch("BRANCH/getAllBranches");
    }
    //getGoodsReceipts
  },
  computed: {
    brands() {
      return this.$store.getters["BRAND/allBrands"];
    },
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },
    productTypes() {
      return this.$store.getters["PRODUCT_TYPE/allProductTypes"];
    },

    selectedAction: {
      get() {
        return this.actionFilter;
      },
      set(val) {
        this.$emit("updateActionFilter", val);
      }
    },

    selectedStatus: {
      get() {
        return this.statusFilter;
      },
      set(val) {
        this.$emit("updateStatusFilter", val);
      }
    }
  },
  methods: {
    updateEmployees(val) {
      this.$emit("updateEmployeesFilter", val);
    },
    updateBrandsFilter(val) {
      this.$emit("updateBrandsFilter", val);
    },
    updateProductTypesFilter(val) {
      this.$emit("updateProductTypesFilter", val);
    },
    updateBranchesFilter(val) {
      this.$emit("updateBranchesFilter", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-area {
  width: 256px;
}
</style>
