<template>
  <v-data-table
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="statusRequest.value === 'loading-getListOffBiz'"
    loading-text="Đang tải dữ liệu"
    :items="listOffBiz"
    item-key="id"
    @click:row="viewDetail($event)"
  >
    <template v-slot:[`item.code`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="copied-label"
            v-bind="attrs"
            v-on="on"
            @click.stop="copyToClipboard(item.code)"
          >
            {{ item.code }}
          </div>
        </template>
        <span>Sao chép</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.serial_number`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="copied-label"
            v-bind="attrs"
            v-on="on"
            @click.stop="copyToClipboard(item.serial_number)"
          >
            {{ item.serial_number }}
          </div>
        </template>
        <span>Sao chép</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.step`]="{ item }">
      <v-chip
        class="font-weight-bold"
        :color="findedStatus(stepList, item.step).color"
        small
        outlined
      >
        {{ findedStatus(stepList, item.step).label }}
      </v-chip>
    </template>

    <template v-slot:[`item.selected_action`]="{ item }">
      <v-chip
        class="font-weight-bold"
        small
        outlined
        :color="findedStatus(ACTION_STATUS, item.selected_action).color"
      >
        {{ findedStatus(ACTION_STATUS, item.selected_action).label }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
import {
  ACTION_STATUS,
  findedStatus
} from "@/modules/NotSoldWarehouse/utils/static-data";

export default {
  data() {
    return {
      ACTION_STATUS: ACTION_STATUS,
      headers: [
        {
          text: "Mã phiếu",
          align: "start",
          sortable: false,
          value: "id"
        },
        {
          text: "Sản phẩm",
          align: "start",
          sortable: false,
          value: "serial.product_name"
        },
        {
          text: "Serial",
          align: "start",
          sortable: false,
          value: "serial.serial_number"
        },
        {
          text: "Người phụ trách",
          align: "start",
          sortable: false,
          value: "assigned_to_user.name"
        },
        {
          text: "Chi nhánh",
          align: "start",
          sortable: false,
          value: "branch.name"
        },
        {
          text: "Khu vực (KV)",
          align: "start",
          sortable: false,
          value: "area"
        },
        {
          text: "Sẵn bán trong KV",
          align: "start",
          sortable: false,
          value: "area"
        },
        {
          text: "Doanh số trưng bày",
          align: "start",
          sortable: false,
          value: "display"
        },
        {
          text: "Thời gian trưng bày",
          align: "start",
          sortable: false,
          value: "created_at"
        },
        {
          text: "Vị trí trưng bày",
          align: "start",
          sortable: false,
          value: "address"
        },
        {
          text: "Trạng thái",
          align: "start",
          sortable: false,
          value: "step"
        },
        {
          text: "Hành động",
          align: "start",
          sortable: false,
          value: "selected_action"
        }
      ],
      stepList: [
        { value: 1, label: "Đang trưng bày", color: "amber" },
        { value: 2, label: "Đã hạ trưng bày", color: "deep-purple" },
        { value: 3, label: "Đã kiểm tra", color: "blue" },
        { value: 4, label: "Xử lý xong", color: "green" }
      ]
    };
  },
  computed: {
    listOffBiz() {
      return this.$store.getters["SAMPLE_SERIAL_NOTE/listOffBiz"];
    },
    statusRequest() {
      return this.$store.getters["SAMPLE_SERIAL_NOTE/statusRequest"];
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    findedStatus(statusList, statusValue) {
      return findedStatus(statusList, statusValue);
    },

    async viewDetail(item) {
      await this.$store.dispatch("SAMPLE_SERIAL_NOTE/getOffBizById", item.id);

      if (this.statusRequest.value === "success-getOffBizById") {
        const offBizDetail = await this.$store.getters[
          "SAMPLE_SERIAL_NOTE/offBizDetail"
        ];
        await this.$router.push({
          name: "not-sold-warehouse_sample-serial-notes-detail",
          params: {
            offBizId: offBizDetail.id
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
  .sub--text {
    ::v-deep p {
      margin-bottom: 0;
    }
  }
}
</style>
